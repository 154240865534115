import { graphql, navigate } from "gatsby"
import React, { useRef, useState } from "react"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

import { GatsbyImage, getImage } from "gatsby-plugin-image"
import SaleFlag from "../../templates/ProductUI/SaleFlag"
import { getProductLink, selectFirstVariant } from "../../utils"
import LeftArrow from "../../images/arrow-icon-left.inline.svg"
import RightArrow from "../../images/arrow-icon-right.inline.svg"
import * as style from "./StaffPicksCarousel.module.scss"

export const fragment = graphql`
  fragment StaffPicksCarouselFragment on WpPage_Flexlayouts_FlexibleLayouts_StaffPicksCarousel {
    headerText
    products {
      product {
        ...SimpleProductQuery
        ...VariableProductQuery
      }
    }
    boxSize
  }
`

const StaffPicksCarousel = ({ headerText, products, boxSize }) => {
  const slider = useRef(null)
  const [mouseMoved, setMouseMoved] = useState(false)

  if (!products) {
    console.warn("No products passed to StaffPicksCarousel")
    return
  }

  const productListWithVariationSelected = []

  products = products.filter(item => item.product !== null)

  products.forEach(({ product }) => {
    const selectedVariant = selectFirstVariant(product)
    selectedVariant && productListWithVariationSelected.push(selectedVariant)
  })

  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    autoplay: false,
    variableWidth: true,
    swipeToSlide: true,
    touchThreshold: 100,
    centerMode: true,
    responsive: [
      {
        breakpoint: 1920,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 799,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  }

  const handleClick = product => {
    const link = getProductLink(product)
    if (!mouseMoved) {
      navigate(link)
    }
  }

  return (
    <div className="mb-4">
      <div className="position-relative">
        <h2
          className={`text-uppercase text-center mb-5 ${style.header}`}
          dangerouslySetInnerHTML={{ __html: headerText }}
        />
        <p
          className={`${style.scroller} text-center d-none d-lg-flex position-absolute`}>
          <a
            role="button"
            aria-label="previous"
            onClick={() => slider.current.slickPrev()}
            className="p-0">
            <LeftArrow />
          </a>
          <a
            role="button"
            aria-label="next"
            onClick={() => slider.current.slickNext()}
            className="p-0">
            <RightArrow />
          </a>
        </p>
      </div>

      <Slider ref={slider} {...settings}>
        {React.Children.toArray(
          productListWithVariationSelected.map(product => {
            const { name, featuredImage, onSale, price, regularPrice } = product

            return (
              <>
                {onSale && <SaleFlag />}
                <div
                  className={
                    boxSize === "small"
                      ? `${style.box} ${style.small}`
                      : style.box
                  }>
                  <a
                    className={style.link}
                    onMouseMove={() => setMouseMoved(true)}
                    onMouseDown={() => setMouseMoved(false)}
                    onMouseUp={() => handleClick(product)}>
                    {featuredImage && (
                      <GatsbyImage
                        className={
                          boxSize === "small"
                            ? `${style.productImg} ${style.small}`
                            : style.productImg
                        }
                        image={getImage(featuredImage.node.localFile)}
                        alt={featuredImage.node.altText}
                      />
                    )}
                    <div className={style.nameWrapper}>
                      <p
                        className={
                          boxSize === "small"
                            ? `${style.productName} ${style.small}`
                            : style.productName
                        }
                        dangerouslySetInnerHTML={{ __html: name }}
                      />
                    </div>
                    {onSale ? (
                      <div className="d-flex justify-content-center ">
                        <p className={`${style.salePrice} mx-3 text-center`}>
                          {price}
                        </p>
                        <p className={`${style.textThrough}  mx-3 text-center`}>
                          {regularPrice}
                        </p>
                      </div>
                    ) : (
                      <div className="d-flex justify-content-center ">
                        <p className={`${style.text}  text-center`}>{price}</p>
                      </div>
                    )}
                  </a>
                </div>
              </>
            )
          })
        )}
      </Slider>
    </div>
  )
}

export default StaffPicksCarousel
