// extracted by mini-css-extract-plugin
export var bgGrey100 = "StaffPicksCarousel-module--bg-grey-100--2a6b9";
export var bgGrey150 = "StaffPicksCarousel-module--bg-grey-150--ffc9e";
export var bgGrey200 = "StaffPicksCarousel-module--bg-grey-200--4eca3";
export var bgGrey300 = "StaffPicksCarousel-module--bg-grey-300--5caf0";
export var bgGrey400 = "StaffPicksCarousel-module--bg-grey-400--7ded3";
export var bgGrey500 = "StaffPicksCarousel-module--bg-grey-500--88256";
export var bgGrey600 = "StaffPicksCarousel-module--bg-grey-600--85240";
export var bgGrey700 = "StaffPicksCarousel-module--bg-grey-700--44403";
export var bgGrey800 = "StaffPicksCarousel-module--bg-grey-800--4b8a2";
export var bgGrey900 = "StaffPicksCarousel-module--bg-grey-900--915bc";
export var box = "StaffPicksCarousel-module--box--10406";
export var header = "StaffPicksCarousel-module--header--0fb4f";
export var link = "StaffPicksCarousel-module--link--bf80a";
export var nameWrapper = "StaffPicksCarousel-module--nameWrapper--89813";
export var productImg = "StaffPicksCarousel-module--productImg--fc675";
export var productName = "StaffPicksCarousel-module--productName--c88e4";
export var salePrice = "StaffPicksCarousel-module--salePrice--5b36b";
export var scroller = "StaffPicksCarousel-module--scroller--8e903";
export var small = "StaffPicksCarousel-module--small--1e492";
export var text = "StaffPicksCarousel-module--text--17fbe";
export var textGrey100 = "StaffPicksCarousel-module--text-grey-100--e159e";
export var textGrey150 = "StaffPicksCarousel-module--text-grey-150--52c02";
export var textGrey200 = "StaffPicksCarousel-module--text-grey-200--bf0ab";
export var textGrey300 = "StaffPicksCarousel-module--text-grey-300--84a03";
export var textGrey400 = "StaffPicksCarousel-module--text-grey-400--2f9cd";
export var textGrey500 = "StaffPicksCarousel-module--text-grey-500--83976";
export var textGrey600 = "StaffPicksCarousel-module--text-grey-600--ce7a6";
export var textGrey700 = "StaffPicksCarousel-module--text-grey-700--6addd";
export var textGrey800 = "StaffPicksCarousel-module--text-grey-800--8f727";
export var textGrey900 = "StaffPicksCarousel-module--text-grey-900--cd099";
export var textThrough = "StaffPicksCarousel-module--textThrough--2fc68";